$(() => {
  const defaultActiveNavbarItemId = $('.navbar-nav .nav-item.active').index();

  function transitionActiveNavbarItem(navbarItemId = 0, isAnimation = true) {
    console.log('> setActiveNavbarItem: ', navbarItemId + 1);

    /* setTimeout(() => {
      const $navbarItem = $('.navbar-nav .nav-item .nav-link').eq(navbarItemId === 0 ? 0 : navbarItemId - 1);
      const offset = $navbarItem.offset();

      if (navbarItemId === 0) {
        $('.navbar-nav .nav-active-line')
          .css('transition', isAnimation ? 'all 0.5s ease' : 'none')
          .css('top', offset.top + $navbarItem.innerHeight() - $(window).scrollTop())
          .css('left', offset.left)
          .css('width', 0);
      } else {
        $('.navbar-nav .nav-active-line')
          .css('transition', isAnimation ? 'all 0.5s ease' : 'none')
          .css('top', offset.top + $navbarItem.innerHeight() - $(window).scrollTop())
          .css('left', offset.left)
          .css('width', $navbarItem.innerWidth());
      }
    }, 250); */
  }

  /* $('.navbar-nav .nav-link').hover(
    (e) => {
      // console.log('> hover')
      const id = $(e.target).parent().index(this);

      transitionActiveNavbarItem(id + 1);
    },
    (e) => {
      // console.log('> out')
      transitionActiveNavbarItem(defaultActiveNavbarItemId);
    }
  ); */

  $(window).on('resize', () => {
    transitionActiveNavbarItem(defaultActiveNavbarItemId, false);
  });
  $('.collapse').on('shown.bs.collapse', () => {
    transitionActiveNavbarItem(defaultActiveNavbarItemId, false);
  });

  //
  transitionActiveNavbarItem(defaultActiveNavbarItemId, false);
});

//

$(() => {
  let subNavID = -1;
  const isMobileNav = () => window.innerWidth < 992;

  $('.navbar-nav .nav-item.nav-item-link').on('click', (e) => {
    const $targetNav = isMobileNav() ? $('.main-nav') : $('.sub-nav');
    const id = $(e.target).parent().index(this);

    console.log('> id =', id);

    if (subNavID === id) {
      if (isMobileNav()) {
        $('.main-nav .wrapper').attr('hidden', (index, attr) => !attr);
      } else {
        $('.sub-nav').attr('hidden', (index, attr) => !attr);
        return;
      }
    }

    // if (id > 3) return

    if (isMobileNav()) {
      $('.sub-nav').attr('hidden', true);
    } else {
      $('.sub-nav').attr('hidden', false);
    }
    subNavID = id;

    if (id === 1) {
      // $('.sub-nav').attr('hidden', (index, attr) => !attr)
      console.log('> isMobileNav =', isMobileNav());
      $targetNav.find('.wrapper.wrapper--1').attr('hidden', false);
      $targetNav.find('.wrapper.wrapper--2').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--3').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--7').attr('hidden', true);
    }

    if (id === 2) {
      // $('.sub-nav').attr('hidden', (index, attr) => !attr)
      $targetNav.find('.wrapper.wrapper--1').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--2').attr('hidden', false);
      $targetNav.find('.wrapper.wrapper--3').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--7').attr('hidden', true);
    }

    if (id === 3) {
      // $('.sub-nav').attr('hidden', (index, attr) => !attr)
      $targetNav.find('.wrapper.wrapper--1').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--2').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--3').attr('hidden', false);
      $targetNav.find('.wrapper.wrapper--7').attr('hidden', true);
    }

    if (id === 7) {
      // $('.sub-nav').attr('hidden', (index, attr) => !attr)
      $targetNav.find('.wrapper.wrapper--1').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--2').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--3').attr('hidden', true);
      $targetNav.find('.wrapper.wrapper--7').attr('hidden', false);
    }
  });
});

$(() => {
  $('.navbar .navbar-toggler').on('click', function () {
    console.log('> color-changed');
    $('.navbar').toggleClass('color-changed');
  });

  $(window).scroll(function () {
    refreshMainNavBg();
  });

  const refreshMainNavBg = () => {
    const scrollTop = $(window).scrollTop();
    $('.main-nav').toggleClass('white-bg', scrollTop > 30);
  };
  refreshMainNavBg();
});

$(() => {
  $(document).on('click', function (e) {
    console.log('> ', $(e.target).closest('.side-menu').length);
    if ($(e.target).closest('.side-menu').length || $(e.target).closest('.advice-menu').length) return;

    $('.side-menu').removeClass('side-menu--open');
    $('.advice-menu').removeClass('advice-menu--open');
    e.stopPropagation();
  });

  $('.side-menu__toggle-btn').on('click', function (e) {
    $('.side-menu').toggleClass('side-menu--open');
    e.stopPropagation();
  });
  $('.side-menu__close-btn').on('click', function (e) {
    $('.side-menu').removeClass('side-menu--open');
    e.stopPropagation();
  });

  $('.advice-menu__toggle-btn').on('click', function (e) {
    $('.advice-menu').toggleClass('advice-menu--open');
    e.stopPropagation();
  });
  $('.advice-menu__close-btn').on('click', function (e) {
    $('.advice-menu').removeClass('advice-menu--open');
    e.stopPropagation();
  });
});

// language
$(() => {
  var url = '';
  var pathname = document.location.pathname;
  pathname = pathname.split('/felix').length > 1 ? pathname.split('/felix')[1] : pathname;
  if (pathname === '/') {
    url = language === 'th' ? './index-en.html' : './index.html';
  } else {
    if (language === 'th') {
      url = '.' + pathname.split('.html')[0] + '-en.html';
    }
    if (language === 'en') {
      url = '.' + pathname.split('-en.html')[0] + '.html';
    }
  }
  $('.language-bar__language a').attr('href', url);
});
